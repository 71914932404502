import React from 'react';
import '../pages/pagesCss/About.css';
import ChatBot from '../Components/ChatBot/ChatBot';
import TravelHimalayaLogo from '../assets/travel-himalayan-logo.png';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1 className="about-title">Discover Your True Self with Mantra Mountain</h1>
        <div className="about-card">
          <div className="card-face card-front">
            <h2>More Than a Trek, It's Your Journey to Freedom</h2>
            <p>Embark on a transformative Himalayan adventure that will awaken your spirit and challenge your limits.</p>
          </div>
          <div className="card-face card-back">
            <h2>Why Choose Mantra Mountain?</h2>
            <ul>
              <li>Discover untouched terrains and hidden parts of yourself</li>
              <li>Foster deep connections with nature, culture, and fellow travelers</li>
              <li>Escape the stress of daily life into mountain serenity</li>
              <li>Embark on a journey of personal growth and self-discovery</li>
            </ul>
          </div>
        </div>
        <div className="about-features">
          <div className="feature">
            <i className="fas fa-mountain"></i>
            <h3>Unplug, Connect, Explore More</h3>
            <p>Leave behind the digital noise and immerse yourself in the raw beauty of the Himalayas.</p>
          </div>
          <div className="feature">
            <i className="fas fa-users"></i>
            <h3>Journey with Expert Companions</h3>
            <p>Our guides don't just lead the way, they inspire transformation and ensure your safety.</p>
          </div>
          <div className="feature">
            <i className="fas fa-heart"></i>
            <h3>Your Path, Your Pace</h3>
            <p>Experience a trek tailored to your aspirations, pushing your boundaries at your own comfort.</p>
          </div>
        </div>
        <div className="about-cta">
          <h2>Ready to Find Yourself Among the Peaks?</h2>
          <Link to="/Package" className="cta-link">
            <button className="cta-button">Begin Your Transformation</button>
          </Link>
        </div>
        <div className="about-mantra">
          <h2>Our Guiding Light</h2>
          <p>
            "Lead us from the unreal to the real<br />
            Lead us from darkness to light<br />
            Lead us from death to immortality<br />
            Aum peace, peace, peace!"
          </p>
          <p>This ancient mantra embodies the essence of your journey with Mantra Mountain. As you trek through the majestic Himalayas, let this mantra guide you to inner peace, clarity, and personal transformation.</p>
        </div>
        <div className="about-partner">
          <h2>Partnering for Unparalleled Experiences</h2>
          <img src={TravelHimalayaLogo} alt="Travel Himalaya Nepal Logo" className="partner-logo" />
          <p>Our partnership with Travel Himalaya Nepal ensures that your journey is not just a trip, but a life-changing experience crafted with expertise and passion.</p>
        </div>
        <ChatBot />
      </div>
    </div>
  );
};

export default About;