import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Background from '../Components/Background/background';
import Hero from "../Components/Hero/Hero";
// import TrekkingPackage from "../Components/Swiper/Swiper";
import ChatBot from '../Components/ChatBot/ChatBot';
import SwiperComponent from "../Components/Swiper/Swiper";
import Footer from "../Components/footer/Footer";
import ReviewSection from "../Components/Review/review";
import MarqueeComponent from "../Components/MarqueeComponent/MarqueeComponent";


const Home = () => {
  let heroData = [
    {
      text1: "Discover Yourself",
      text2: "Among the Peaks",
      subtext: "Experience a journey of personal growth and self-discovery in the Himalayas."
    },
    {
      text1: "Unplug, Connect,",
      text2: "Explore More",
      subtext: "Escape the digital noise and immerse yourself in the raw beauty of nature."
    },
    {
      text1: "Where Adventure Meets",
      text2: "Inner Peace",
      subtext: "Find harmony between exhilarating challenges and serene moments of reflection."
    },
  ];

  const [heroCount, setHeroCount] = useState(0);
  const [playStatus, setPlayStatus] = useState(true);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setHeroCount((count) => (count === 2 ? 0 : count + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="google-site-verification" content="7ptot4gSLe6uxuvahIroDX-J8ZZLm5Q1494CTdySze4" />
        <title>Mantra Mountain</title>
        <meta name="description" content="Explore the Himalayas with our trekking packages" />
      </Helmet>

      <Background playStatus={playStatus} heroCount={heroCount} />
      <Hero
        heroData={heroData[heroCount]}
        heroCount={heroCount}
        setHeroCount={setHeroCount}
      />
      <SwiperComponent />
      
      <div><ReviewSection /></div>
      
      
      <div className="chatbot-section">
        <ChatBot />
        
      </div>
     

      <MarqueeComponent />
      <Footer />
    </div> 
    
    
  );
};

export default Home;
