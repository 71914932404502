import React, { useState } from 'react';
import axios from 'axios';
import './CreateBlog.css'

const CreateBlog = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://localhost:5000/api/blogs/create', { title, content, image });
            alert('Blog post created successfully');
            // Optionally, reset the form
            setTitle('');
            setContent('');
            setImage('');
        } catch (error) {
            console.error('Failed to create blog:', error);
            alert('Failed to create blog. Please try again.');
        }
    };

    return (
        <div className="blog-post-container">
  <h2 className="blog-post-title">Create Blog Post</h2>
  <form onSubmit={handleSubmit} className="blog-post-form">
    <div className="form-group">
      <label className="form-label">Title</label>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
        className="form-input"
      />
    </div>
    <div className="form-group">
      <label className="form-label">Content</label>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        required
        className="form-textarea"
      />
    </div>
    <div className="form-group">
      <label className="form-label">Image URL</label>
      <input
        type="text"
        value={image}
        onChange={(e) => setImage(e.target.value)}
        required
        className="form-input"
      />
    </div>
    <button type="submit" className="submit-button">Create Blog</button>
  </form>
</div>

    );
};

export default CreateBlog;
