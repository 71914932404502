import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const EditPackage = ({ match }) => {
  const [packageData, setPackageData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPackage = async () => {
      const response = await axios.get(`/api/packages/${match.params.id}`);
      setPackageData(response.data);
      setLoading(false);
    };
    fetchPackage();
  }, [match.params.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission for editing
  };

  if (loading) return <p>Loading...</p>;

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" value={packageData.title} onChange={(e) => setPackageData({ ...packageData, title: e.target.value })} />
      {/* Add other form fields here */}
      <button type="submit">Update Package</button>
    </form>
  );
};
EditPackage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditPackage;

